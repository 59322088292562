import React, {Component} from 'react';
import Header from "../components/header";
import Footer from "../components/footer";
import Car from "../images/icons/sedan.svg";
import Play from "../images/icons/play.svg";
import Contract from "../images/contract.svg";
import Odessa from "../images/cities/odessa.png";
import Mercedes from "../images/brands/mercedes-benz.svg";
import NewsBig from "../images/news/big.png";
import NewsSmall from "../images/news/small.png";
import {Accordion, Card} from "react-bootstrap";
import ArrowRight from "../images/icons/arrow-right.svg";

function OptionBlock(props){
    return(
        <div className="col option">
            <div className="option-image">
                <img src={props.image} alt="icon" />
            </div>
            <div className="option-title">
                <a href="#">{props.title}</a>
            </div>
        </div>
    );
}

function BenefitBlock(props){
    return(
        <div className="col">
            <div className="benefit-image">
                <img src={props.image} alt="icon" />
            </div>
            <div className="benefit-title">
                {props.title}
            </div>
        </div>
    );
}

function CityBlock(props){
    return(
        <div className="col-3">
            <div className="city-image">
                <img src={props.image} alt="city" />
            </div>
            <div className="city">
                <div className="city-title">
                    {props.title}
                </div>
                <div className="city-price">
                    {props.minPrice} — {props.maxPrice}
                </div>
            </div>
        </div>
    );
}
function SelectBlock(props){
    return(
        <div className="select-block">
            <div className="select-content">
                <div className="select-icon">
                    <img src={props.image} alt="icon" />
                </div>
                <div className="select-text">
                    {props.content}
                </div>
            </div>
        </div>

    );
}

function AcordionBlock(props){
    return(
        <div className="col-4">
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="card-toggle">
                        <div className="card-title">
                            {props.title}
                        </div>
                        <div>
                            <img src={ArrowRight} />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {props.content}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
}

class Secondpage extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="container padding-top-30 padding-bottom-30">
                    <div className="row">
                        <nav className="breadcrumbs">
                            <a className="nav-link">Главная</a>
                            <a className="nav-link active">Страхование осаго</a>
                        </nav>
                    </div>
                </div>
                <div className="container margin-bottom-70">
                    <div className="row justify-content-between">
                        <h1>
                            Страховка ОСАГО по лучшей цене
                        </h1>
                        <div className="d-flex">
                            <button className="button green margin-right-20">По параметрам</button>
                            <button className="button nofill">По гос. номеру</button>
                        </div>
                    </div>
                    <div className="row margin-top-40 margin-bottom-40 label">
                        Тип транспортного средства
                        <div className="icon-info"></div>
                    </div>
                    <div className="row margin-bottom-70">
                        <OptionBlock
                            image={Car}
                            title="Легковой автомобиль"
                        />
                        <OptionBlock
                            image={Car}
                            title="Грузовой автомобиль"
                        />
                        <OptionBlock
                            image={Car}
                            title="Автобус"
                        />
                        <OptionBlock
                            image={Car}
                            title="Мотоцикл"
                        />
                        <OptionBlock
                            image={Car}
                            title="Прицеп"
                        />
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-6">
                            <div className="row margin-bottom-20 label">
                                Объем двигателя
                                <div className="icon-info"></div>
                            </div>
                            <div className="row">
                                <div className="checkboxes">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="1" name="1" value="indigo" />
                                        <label htmlFor="1">Электромобиль</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="2" name="2" value="indigo" />
                                        <label htmlFor="2">1601 - 2000 см3</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="3" name="3" value="indigo" />
                                        <label htmlFor="3">от 3001 см3</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="4" name="4" value="indigo" />
                                        <label htmlFor="4">до 1600 см3</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="5" name="5" value="indigo" />
                                        <label htmlFor="5">2001 - 3000 см3</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row justify-content-between">
                                <div className="col-6">
                                    <div className="row margin-bottom-20 label">
                                        Дополнительные опции
                                        <div className="icon-info"></div>
                                    </div>
                                    <div className="row">
                                        <div className="checkboxes">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="11" name="11" value="indigo" />
                                                <label htmlFor="11">Дополнительная опция</label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="22" name="22" value="indigo" />
                                                <label htmlFor="22">Дополнительная опция</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row margin-bottom-20 label">
                                        Место прописки
                                        <div className="icon-info"></div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group">
                                            <input type="text" className="form-control border-radius-99 w-100" placeholder="Lviv.." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-end">
                        <button className="button green">Рассчитать</button>
                    </div>
                </div>
                <div className="container margin-bottom-70">
                    <div className="row">
                        <div>
                            <h2 className="line-before orange">СП Брокер — помощь при наступлении страхового случая</h2>
                            <p className="margin-top-30 margin-bottom-30">
                                Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым  услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников.
                                Функционал сайта — легкий способ сравнить, выбрать и оформить страховой или банковский продукт.
                            </p>
                            <div className="d-flex">
                                <button className="button orange">Заказать звонок</button>
                                <button className="button nofill margin-left-20">подробнее</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container margin-bottom-70">
                    <div className="row">
                        <div className="iframe">
                            <img src={Play} alt="icon" />
                        </div>
                    </div>
                </div>
                <div className="container margin-bottom-70">
                    <div className="row margin-bottom-30 justify-content-between">
                        <h3 className="line-before green align-self-center">Преимущества работы с нами</h3>
                    </div>
                    <div className="row benefits">
                        <BenefitBlock
                            image={Contract}
                            title="У нас выгодные условия договора"
                        />
                        <BenefitBlock
                            image={Contract}
                            title="Мы поможем клиенту в любой ситации"
                        />
                        <BenefitBlock
                            image={Contract}
                            title="У нас доступны постоянные скидки"
                        />
                        <BenefitBlock
                            image={Contract}
                            title="Возможно оплата разными способами"
                        />
                        <BenefitBlock
                            image={Contract}
                            title="Только надежные страховые компании"
                        />
                    </div>
                </div>
                <div className="container padding-bottom-70">
                    <div className="row margin-bottom-30 justify-content-between">
                        <h3 className="line-before green align-self-center">Cтрахование по городам</h3>
                        <button className="button nofill border-green">Все города</button>
                    </div>
                    <div className="row margin-bottom-20">
                        <nav className="choice">
                            <a className="nav-link" href="#">Электромобиль</a>
                            <a className="nav-link active" href="#">до 1600 см3</a>
                            <a className="nav-link" href="#">1601 — 2000 см3</a>
                            <a className="nav-link" href="#">2001 — 3000 см3</a>
                            <a className="nav-link" href="#">2001 — 3000 см3</a>
                        </nav>
                    </div>
                    <div className="row cities">
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                    </div>
                </div>
                <div className="container margin-bottom-70">
                    <div className="row">
                        <div className="scroll-x">
                            <div className="col-4">
                                <h5 className="margin-bottom-30">Цена Осаго на авто</h5>
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                            </div>
                            <div className="col-4">
                                <h5 className="margin-bottom-30">Цена Осаго на авто</h5>
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                            </div>
                            <div className="col-4">
                                <h5 className="margin-bottom-30">Цена Осаго на авто</h5>
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                            </div>
                            <div className="col-4">
                                <h5 className="margin-bottom-30">Цена Осаго на авто</h5>
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                                <SelectBlock
                                    image={Mercedes}
                                    content="Mercedes-benz"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container margin-bottom-70">
                    <div className="row margin-bottom-30 justify-content-between">
                        <h3 className="line-before green align-self-center">Что нужно знать про осаго</h3>
                        <button className="button nofill border-green">Перейти в блог</button>
                    </div>
                    <div className="row news">
                        <div className="col-8 news-block">
                            <img src={NewsBig} alt="image" className="news-image" />
                            <div className="news-block-content">
                                <a href="#">
                                    <div className="news-headline">
                                        Путешествуйте безопасно! Турстраховка, которая покрывает коронавирус
                                    </div>
                                </a>
                                <div className="news-date">
                                    25 августа 2020
                                </div>
                            </div>
                        </div>

                        <div className="col-4 news-block">
                            <img src={NewsSmall} alt="image" className="news-image" />
                            <div className="news-block-content">
                                <a href="#">
                                    <div className="news-headline">
                                        Путешествуйте безопасно! Турстраховка, которая покрывает коронавирус
                                    </div>
                                </a>
                                <div className="news-date">
                                    25 августа 2020
                                </div>
                            </div>
                        </div>

                        <div className="col-4 news-block">
                            <img src={NewsSmall} alt="image" className="news-image" />
                            <div className="news-block-content">
                                <a href="#">
                                    <div className="news-headline">
                                        Путешествуйте безопасно! Турстраховка, которая покрывает коронавирус
                                    </div>
                                </a>
                                <div className="news-date">
                                    25 августа 2020
                                </div>
                            </div>
                        </div>

                        <div className="col-4 news-block">
                            <img src={NewsSmall} alt="image" className="news-image" />
                            <div className="news-block-content">
                                <a href="#">
                                    <div className="news-headline">
                                        Путешествуйте безопасно! Турстраховка, которая покрывает коронавирус
                                    </div>
                                </a>
                                <div className="news-date">
                                    25 августа 2020
                                </div>
                            </div>
                        </div>

                        <div className="col-4 news-block">
                            <img src={NewsSmall} alt="image" className="news-image" />
                            <div className="news-block-content">
                                <a href="#">
                                    <div className="news-headline">
                                        Путешествуйте безопасно! Турстраховка, которая покрывает коронавирус
                                    </div>
                                </a>
                                <div className="news-date">
                                    25 августа 2020
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container padding-bottom-70">
                    <div className="row">
                        <div className="bottom-text">
                            <h4 className="margin-bottom-30">Hotline.finance — cервис сравнения и онлайн оформления банковских и страховых продуктов.</h4>
                            <p>
                                Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников.
                                <br/><br/>
                                Функционал сайта — легкий способ сравнить, выбрать и оформить страховой или банковский
                                продукт. С помощью фильтров, сортировок и сравнения можно за несколько минут сделать
                                осознанный выбор выгодного кредита, кредитной карты, депозита, надежной туристической
                                страховки, подходящего полиса ОСАГО, КАСКО и страховки жилья. У пользователей также есть
                                возможность исследовать уровень финансовой устойчивости учреждения, изучить отзывы и
                                опыт использования клиентами услуг страховых и банковских организаций.
                                <br/><br/>
                                На данный момент онлайн-процесс, от выбора до получения финансовой или страховой услуги,
                                полностью реализован для ОСАГО, туристической страховки, страховки жилья, карты авто
                                ассистанса, онлайн-кредитов и некоторых кредитных карт. Зеленую карту для выезда за
                                границу можно оформить онлайн, однако после этого нужно будет забрать бумажный оригинал
                                (отправляем Новой Почтой за счет hotline.finance). Сравнить предложения КАСКО и цены на
                                страховки можно на hotline.finance, однако для оформления нужно будет прислать заявку в
                                страховую. На оформление кредита в банке, депозита Вы сможете подать заявку в банковское
                                учреждение. Мы работаем, чтобы перевести предоставление максимального количества
                                финансовых продуктов в режим онлайн.
                            </p>
                            <div className="row faq">
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Secondpage;