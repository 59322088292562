import React, {Component} from 'react';
import Header from "../components/header";

import Car from "../images/icons/car-insurance.svg";
import Keyproduct from "../images/key-product.svg";
import Pzu from "../images/partners/pzu.png";
import Unives from "../images/partners/unives.png";
import Avatar from "../images/avatar.svg";
import Contract from "../images/contract.svg";
import Odessa from "../images/cities/odessa.png";
import {Accordion, Card} from "react-bootstrap";
import ArrowRight from "../images/icons/arrow-right.svg";
import Footer from "../components/footer";

function OptionBlock(props){
    return(
        <div className="col option">
            <div className="option-image">
                <img src={props.image} alt="icon" />
            </div>
            <div className="option-title">
                <a href="#">{props.title}</a>
            </div>
        </div>
    );
}

function KeyproductBlock(props){
    return(
        <div className={`row key-product ${props.reverse}`}>
            <div className="col-6">
                <div className="row">
                    <div>
                        <h5>Электронный полис ОСАГО за 5 минут</h5>
                        <p>
                            Автогражданка онлайн без общения с агентами и посещения страховых.
                            Сравните цены, выберите лучшую программу, оплатите картой без
                            комиссии и действующий полис уже на Вашем e-mail.
                        </p>
                        <div className="row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="mylabel">Транспортное средство</label>
                                    <select className="form-control">
                                        <option disabled selected>Choose...</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="mylabel">Объем двигателя</label>
                                    <select className="form-control">
                                        <option disabled selected>Choose...</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label className="mylabel">Город прописки</label>
                                    <input type="text" className="form-control" placeholder="Lviv.."/>
                                </div>
                            </div>
                            <div className="col-6 d-flex">
                                <div className="form-group w-100 d-flex">
                                    <button className="form-button">Узнать цены</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="row">
                    <img src={Keyproduct} alt="keyproduct" />
                </div>
            </div>
            <div className="keyproduct-footer">
                ОСАГО в этих городах:
                <nav>
                    <a className="nav-link" href="#">Киев</a>
                    <a className="nav-link" href="#">Львов</a>
                    <a className="nav-link" href="#">Одесса</a>
                    <a className="nav-link" href="#">Харьков</a>
                    <a className="nav-link" href="#">Днепр</a>
                    <a className="nav-link" href="#">Винница</a>
                </nav>
            </div>
        </div>
    );
}

function ReviewBlock(props){
    return(
        <div className="col-4">
            <div className="row">
                <div className="avatar">
                    <img src={props.avatar} alt="avatar" />
                </div>
                <div className="review-content">
                    <div className="review-author">
                        {props.author}
                    </div>
                    <div className="review-date">
                        {props.date}
                    </div>
                    <div className="review-text">
                        {props.content}
                    </div>
                </div>
            </div>
        </div>
    );
}

function BenefitBlock(props){
    return(
        <div className="col">
            <div className="benefit-image">
                <img src={props.image} alt="icon" />
            </div>
            <div className="benefit-title">
                {props.title}
            </div>
        </div>
    );
}


function CityBlock(props){
    return(
        <div className="col-3">
            <div className="city-image">
                <img src={props.image} alt="city" />
            </div>
            <div className="city">
                <div className="city-title">
                    {props.title}
                </div>
                <div className="city-price">
                    {props.minPrice} — {props.maxPrice}
                </div>
            </div>
        </div>
    );
}
function AcordionBlock(props){
    return(
        <div className="col-4">
            <Accordion>
                <Card>
                    <Accordion.Toggle as={Card.Header} variant="link" eventKey="0" className="card-toggle">
                        <div className="card-title">
                            {props.title}
                        </div>
                        <div>
                            <img src={ArrowRight} />
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            {props.content}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
}

class Mainpage extends Component {
    render() {
        return (
        <>
            <Header />
            <div className="border-bottom">
                <div className="container padding-top-70 padding-bottom-70">
                    <div className="row">
                        <h1>Онлайн-оформление страховок</h1>
                    </div>
                    <div className="row options">
                        <OptionBlock
                            image={Car}
                            title="Автострахование"
                        />
                        <OptionBlock
                            image={Car}
                            title="Туристическое страхование"
                        />
                        <OptionBlock
                            image={Car}
                            title="Медицинское страхование"
                        />
                        <OptionBlock
                            image={Car}
                            title="Страхование жизни"
                        />
                        <OptionBlock
                            image={Car}
                            title="Страхование недвижимости"
                        />
                    </div>
                    <div className="row">
                        <div>
                            <h2 className="line-before orange">СП Брокер — помощь при наступлении страхового случая</h2>
                            <p className="margin-top-30 margin-bottom-30">
                                Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым  услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников.
                                Функционал сайта — легкий способ сравнить, выбрать и оформить страховой или банковский продукт.
                            </p>
                            <div className="d-flex">
                                <button className="button orange">Заказать звонок</button>
                                <button className="button nofill margin-left-20">подробнее</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className="container padding-top-70 padding-bottom-70">
                    <div className="row">
                        <div className="col-6">
                            <div className="row">
                                <h3 className="line-before green">Наша компания</h3>
                                <div className="about">
                                    <div className="about-content">
                                        О компании СП Брокер
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <h3 className="line-before green">Наши партнеры</h3>
                                <div className="row partners">
                                    <div className="col-4">
                                        <img src={Pzu} alt="partner" />
                                    </div>
                                    <div className="col-4">
                                        <img src={Unives} alt="partner" />
                                    </div>
                                    <div className="col-4">
                                        <img src={Pzu} alt="partner" />
                                    </div>
                                    <div className="col-4">
                                        <img src={Unives} alt="partner" />
                                    </div>
                                    <div className="col-4">
                                        <img src={Pzu} alt="partner" />
                                    </div>
                                    <div className="col-4">
                                        <img src={Unives} alt="partner" />
                                    </div>
                                    <div className="col-4">
                                        <img src={Pzu} alt="partner" />
                                    </div>
                                    <div className="col-4">
                                        <img src={Unives} alt="partner" />
                                    </div>
                                    <div className="col-4">
                                        <img src={Pzu} alt="partner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row margin-top-70">
                        <h3 className="line-before green margin-bottom-30">Наши ключевые продукты</h3>
                        <KeyproductBlock />
                        <KeyproductBlock
                            reverse="reverse"
                        />
                    </div>
                </div>
                <div className="container padding-bottom-70">
                    <div className="row margin-bottom-30 justify-content-between">
                        <h3 className="line-before green align-self-center">Отзывы о нас</h3>
                        <button className="button nofill border-green">Оставить отзыв</button>
                    </div>
                    <div className="row review">
                        <ReviewBlock
                            avatar={Avatar}
                            author="Александр Петров"
                            date="05.11.2020"
                            content="Lorem Ipsum - это текст-«рыба», часто используемый в
                                     печати и вэб-дизайне. Lorem Ipsum является стандартной
                                     «рыбой» для текстов на латинице с начала XVI века."
                        />
                        <ReviewBlock
                            avatar={Avatar}
                            author="Александр Петров"
                            date="05.11.2020"
                            content="Lorem Ipsum - это текст-«рыба», часто используемый в
                                     печати и вэб-дизайне. Lorem Ipsum является стандартной
                                     «рыбой» для текстов на латинице с начала XVI века."
                        />
                        <ReviewBlock
                            avatar={Avatar}
                            author="Александр Петров"
                            date="05.11.2020"
                            content="Lorem Ipsum - это текст-«рыба», часто используемый в
                                     печати и вэб-дизайне. Lorem Ipsum является стандартной
                                     «рыбой» для текстов на латинице с начала XVI века."
                        />
                    </div>
                </div>
                <div className="container padding-bottom-70">
                    <div className="row margin-bottom-30 justify-content-between">
                        <h3 className="line-before green align-self-center">Преимущества работы с нами</h3>
                    </div>
                    <div className="row benefits">
                        <BenefitBlock
                            image={Contract}
                            title="У нас выгодные условия договора"
                        />
                        <BenefitBlock
                            image={Contract}
                            title="Мы поможем клиенту в любой ситации"
                        />
                        <BenefitBlock
                            image={Contract}
                            title="У нас доступны постоянные скидки"
                        />
                        <BenefitBlock
                            image={Contract}
                            title="Возможно оплата разными способами"
                        />
                        <BenefitBlock
                            image={Contract}
                            title="Только надежные страховые компании"
                        />
                    </div>
                </div>
                <div className="container padding-bottom-70">
                    <div className="row margin-bottom-30 justify-content-between">
                        <h3 className="line-before green align-self-center">Cтрахование по городам</h3>
                        <button className="button nofill border-green">Все города</button>
                    </div>
                    <div className="row cities">
                        <CityBlock 
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                        <CityBlock
                            image={Odessa}
                            title="Kiev"
                            minPrice="968 грн."
                            maxPrice="2053 грн."
                        />
                    </div>
                </div>
                <div className="container padding-bottom-70">
                    <div className="row">
                        <div className="bottom-text">
                            <h4 className="margin-bottom-30">Hotline.finance — cервис сравнения и онлайн оформления банковских и страховых продуктов.</h4>
                            <p>
                                Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников.
                                <br/><br/>
                                Функционал сайта — легкий способ сравнить, выбрать и оформить страховой или банковский
                                продукт. С помощью фильтров, сортировок и сравнения можно за несколько минут сделать
                                осознанный выбор выгодного кредита, кредитной карты, депозита, надежной туристической
                                страховки, подходящего полиса ОСАГО, КАСКО и страховки жилья. У пользователей также есть
                                возможность исследовать уровень финансовой устойчивости учреждения, изучить отзывы и
                                опыт использования клиентами услуг страховых и банковских организаций.
                                <br/><br/>
                                На данный момент онлайн-процесс, от выбора до получения финансовой или страховой услуги,
                                полностью реализован для ОСАГО, туристической страховки, страховки жилья, карты авто
                                ассистанса, онлайн-кредитов и некоторых кредитных карт. Зеленую карту для выезда за
                                границу можно оформить онлайн, однако после этого нужно будет забрать бумажный оригинал
                                (отправляем Новой Почтой за счет hotline.finance). Сравнить предложения КАСКО и цены на
                                страховки можно на hotline.finance, однако для оформления нужно будет прислать заявку в
                                страховую. На оформление кредита в банке, депозита Вы сможете подать заявку в банковское
                                учреждение. Мы работаем, чтобы перевести предоставление максимального количества
                                финансовых продуктов в режим онлайн.
                            </p>
                            <div className="row faq">
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                                <AcordionBlock
                                    title="СЕО вопрос и ответ"
                                    content="Задача проекта Hotline.finance — помочь в выборе правильного финансового продукта.
                                Главная цель — дать доступ украинцам к лучшим финансовым услугам от ведущих банков,
                                страховых компаний и кредитных организаций в любой точке Украины без посредников."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
        );
    }
}

export default Mainpage;