import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/fonts.scss';
import './styles/margins.scss';
import './styles/styles.scss';

import Mainpage from "./pages/mainpage";
import Secondpage from "./pages/secondpage";

function App() {
  return (
      <BrowserRouter>
          <Switch>
            <Route component={Mainpage} path="/" exact />
            <Route component={Secondpage} path="/2" />
          </Switch>
      </BrowserRouter>
  );
}

export default App;
