import React, {Component} from 'react';

import Logo from '../images/logo.svg';
import Money from '../images/icons/money.svg';
import Phone from '../images/icons/phone.svg';
import DayNight from '../images/icons/day-night.svg';
import {NavLink} from "react-router-dom";

class Header extends Component {
    render() {
        return (
            <header>

                <div className="header-row top">
                    <div className="container">
                        <div className="row justify-content-between">

                            <div className="phone-mail">
                                <div className="icon-and-title">
                                    <div className="icon-18 icon-phone"></div>
                                    +38 (099) 000 00 00
                                </div>
                                <div className="mail">
                                    info@site.com.ua
                                </div>
                            </div>

                            <div className="icon-and-title text-uppercase">
                                <div className="icon-18 icon-login"></div>
                                Личный кабинет
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-row main">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-3">
                                <div className="row">
                                    <div className="logo">
                                        <a href="/">
                                            <img src={Logo} alt="logo" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="row">
                                    <div className="input-group search">
                                        <input type="text" className="form-control" placeholder="Последние запросы: Страховка на авто" />
                                        <button className="search-submit" type="submit"></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="row justify-content-end header-icons">
                                    <div className="icon circle">
                                        <img src={Money} alt="icon" />
                                    </div>
                                    <div className="icon circle">
                                        <img src={Phone} alt="icon" />
                                    </div>
                                    <div className="icon circle">
                                        <b>UA</b>
                                    </div>
                                    <div className="icon circle">
                                        <img src={DayNight} alt="icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-row menu">
                    <div className="container">
                        <div className="row">
                            <nav className="mainmenu">
                                <NavLink to="/2" className="nav-link">О компании</NavLink>
                                <a href="#" className="nav-link">Услуги страхования</a>
                                <a href="#" className="nav-link">Страхование для бизнеса</a>
                                <a href="#" className="nav-link">Помощь в получении выплаты</a>
                                <a href="#" className="nav-link">Страховой случай</a>
                                <a href="#" className="nav-link">Блог</a>
                                <a href="#" className="nav-link">Контакты</a>
                                <a href="#" className="nav-link"><b>Заказать звонок</b></a>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;