import React, {Component} from 'react';

import Youtube from '../images/icons/youtube.svg';

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container footer-container">
                    <div className="row">

                        <div className="col">
                            <div className="row">
                                <div>
                                    <h5 className="margin-bottom-10">Страховая комания «СП Брокер»</h5>
                                    <div className="margin-bottom-10">
                                        Задача проекта Hotline.finance — помочь
                                        в выборе правильного финансового продукта.
                                    </div>
                                    <div className="margin-bottom-10">
                                        <b>Адрес</b> - Киев, Украина
                                    </div>
                                    <div className="margin-bottom-10">
                                        <b>Телефоны:</b><br />
                                        <a href="#">+38 (000) 000 00 00</a><br />
                                        <a href="#">+38 (000) 000 00 00</a>
                                    </div>
                                    <div>
                                        <b>E-mail:</b>
                                        <a href="#">+info@spbr.com.ua</a>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col">
                            <div className="row">
                                <div>
                                    <h5 className="margin-bottom-10">facebook</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="row">
                                <div>
                                    <h5 className="margin-bottom-10">СП Брокер</h5>
                                    <ul className="flex-column">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Главная</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">О компании</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Услуги страхования</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Страхование для бизнеса</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Страховой случай</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Блог</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Контакты</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="row">
                                <div>
                                    <h5 className="margin-bottom-10">Услуги</h5>
                                    <ul className="flex-column">
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Автострахование</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Туристическое страхование</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Медицинское страхование</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Страхование недвижимости</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Страхование жизни и здоровья</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Накопительное страхование</a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="#" className="nav-link">Страхование грузов</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col">
                            <div className="row justify-content-end">
                                <div className="margin-right-10">
                                    <div className="icon-36">
                                        <img src={Youtube} alt="icon" />
                                    </div>
                                    <div className="icon-36">
                                        <img src={Youtube} alt="icon" />
                                    </div>
                                </div>
                                <div>
                                    <div className="icon-36">
                                        <img src={Youtube} alt="icon" />
                                    </div>
                                    <div className="icon-36">
                                        <img src={Youtube} alt="icon" />
                                    </div>
                                    <div className="icon-36">
                                        <img src={Youtube} alt="icon" />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row justify-content-center">
                            При копировании материалов с сайта обязательно письменное подтверждение редакции «СП Брокер» © Все права защищены. Купить страховку 2005-2020.
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;